<template>
  <section v-lazy-container="{ selector: '.v-lazyload' }">
    <van-notice-bar left-icon="volume-o" :scrollable="false" v-if="notices.length > 0">
      <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in notices" :key="index">{{ item.title }}</van-swipe-item>
        <!--        <van-swipe-item>内容 2</van-swipe-item>-->
        <!--        <van-swipe-item>内容 3</van-swipe-item>-->
      </van-swipe>
    </van-notice-bar>

    <div class="banner-box" v-if="banners.length > 0">
      <swiper :options="swiperOption" ref="bannerSwiper">
        <swiper-slide v-for="(item, index) in banners" :key="index">
          <a :href="item.link || '#'" class="banner-swiper-item">
            <img :src="item.url + '?x-oss-process=image/resize,w_750/format,jpg/quality,q_80'" alt="" />
          </a>
        </swiper-slide>
        <template #pagination>
          <div class="swiper-pagination banner-swiper-pagination"></div>
        </template>
        <!--        <div class="swiper-pagination banner-swiper-pagination" slot="pagination"></div>-->
      </swiper>
    </div>

    <div class="nav-wrap">
      <div class="nav-box">
        <div class="column-row column-4">
          <a :href="item.url" v-for="(item, index) in category" :key="index">
            <img :src="item.icon_url" alt="" />
            <p>{{ item.name }}</p>
          </a>
        </div>
      </div>
    </div>

<!--    <div class="recommend" v-if="goods.length>0">-->
<!--      <div class="recommend-container" data-spm="recommend">-->
<!--        <div class="recommend-hd">-->
<!--          <img src="https://img.alicdn.com/tfs/TB1V2eQrKSSBuNjy0FlXXbBpVXa-966-114.png" class="recommend-hd-bg"-->
<!--            alt="" />-->
<!--        </div>-->
<!--        <div class="recommend-item" v-for="(item, index) in goods" :key="index">-->
<!--          <a class="recommend-img-wrapper triggerClick" data-index="0" :href="item.url">-->
<!--            <img class="recommend-img lazyload" :src="item.image" alt="" />-->
<!--          </a>-->
<!--          <a class="recommend-info triggerClick" :href="item.url">-->
<!--            <div class="recommend-title">-->
<!--              <span class="recommend-title-p">{{ item.name }}</span>-->
<!--            </div>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="recommend" v-if="isShowHomeRecom">
      <div class="recommend-container" data-spm="recommend">
        <div class="recommend-hd">
          <div class="recommend-title">─ ─ 京票推荐 ─ ─</div>
        </div>
        <div class="recommend-item" v-for="(item, index) in goods" :key="index">
          <a class="recommend-img-wrapper triggerClick" data-index="0" :href="item.url">
            <img class="recommend-img lazyload" :src="item.mainPicture" alt="" />
          </a>
          <a class="recommend-info triggerClick" :href="item.url">
            <div class="recommend-title">
              <span class="recommend-title-p">{{ item.name }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>

    <tab-bar tab="home" />
  </section>
</template>

<script>
import TabBar from '@/components/TabBar'
import * as pageUtil from '@/utils/pageUtil'
import { Toast, NoticeBar, Swipe, SwipeItem, Dialog } from 'vant'
import request from '@/utils/request'
import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/css'

export default {
  components: {
    TabBar,
    Swiper,
    SwiperSlide,
    VanNoticeBar: NoticeBar,
    VanSwipeItem: SwipeItem,
    VanSwipe: Swipe
  },
  data () {
    return {
      banners: [],
      movie: {},
      swiperOption: {
        autoplay: true,
        loop: true,
        lazy: {
          loadPrevNext: true
        },
        pagination: {
          el: '.banner-swiper-pagination'
        }
      },
      swiperOption1: {
        pagination: {
          el: '.ecard-swiper-pagination'
        }
      },
      swiperOption2: {
        paginationClickable: true,
        // centeredSlides: true,
        // slidesPerView: 1,
        // initialSlide: 1,
        // watchActiveIndex: true,
        pagination: {
          el: '.vip-swiper-pagination',
          type: 'progressbar'
        }
      },
      showLogin: false,
      code: '',
      codepwd: '',
      category: [],
      goods: [],
      notices: [],
      showMovie: false,
      isShowHomeRecom: false,
      alert: null
    }
  },
  created () {
    // Dialog.alert({
    //   title: '通知',
    //   messageAlign: 'left',
    //   message:
    //     '2022年春节将至，平台部分产品因春节物流放假及疫情等因素将于1月22日起陆续停止发货。春节期间可正常下单，订单预计在2月15日后（以快递、物流恢复时间为准）按下单先后顺序陆续发货。由此给您带来的不便敬请谅解。\n' +
    //     '线下门店消费以门店实际情况为准，感谢您一直以来的理解与支持。'
    // }).then(() => {
    //   // on close
    // })
    // if (!sessionStorage.getItem('_index_tip')) {
    //   Dialog.alert({
    //     title: '温馨提示',
    //     message: '因部分供应商放假，即日起，京票优选、京票严选 亲子儿童 栏目内商品将在假期结束后陆续发出，请您耐心等待！'
    //   }).then(() => {
    //     // on close
    //     sessionStorage.setItem('_index_tip', '1')
    //   })
    // }
    this.getHomeData()
    this.getUserInfo()
    this.getRecommendGoods()
  },
  methods: {
    getHomeData () {
      document.title = '京票商城'
    },
    getUserInfo () {
      request('/mall/getHomeData').then((res) => {
        // if (res.data.code !== 0) {
        //   this.showLogin = true
        // }
        this.banners = res.data.banners
        this.category = res.data.categorys
        this.isShowHomeRecom = res.data.isShowHomeRecom
        // const goods = res.data.goods
        // goods.forEach((item) => {
        //   switch (item.channel) {
        //     case 'youxuan':
        //     case 'srm':
        //       item.url = '/mall/youxuan-product?sku=' + item.sku
        //       break
        //     case 'jd':
        //       item.url = '/jd/product?sku=' + item.sku
        //       break
        //     case 'jdbook':
        //       item.url = '/book/product?sku=' + item.sku
        //       break
        //     case 'yzh':
        //       item.url = '/yzh2/product?sku=' + item.sku
        //       break
        //   }
        // })
        // this.goods = goods
        this.notices = res.data.notices
        this.showMovie = res.data.showMovie
        this.alert = res.data.alert.length > 0 ? res.data.alert[0] : null
        const needBindKq = res.data.needBindKq
        if (needBindKq) {
          window.location.href = '/my/index'
        } else {
          if (res.data.redirectUrl) {
            window.location.href = res.data.redirectUrl
          } else {
            pageUtil.showPage()
          }
        }

        if (this.alert) {
          Dialog.alert({
            title: '温馨提示',
            messageAlign: 'left',
            message: this.alert.title
          }).then(() => {
            // on close
          })
        }
        // if (this.category.length === 0) {
        //   window.location.replace('/my/index')
        // } else {
        //   pageUtil.showPage()
        // }
      })
    },
    onSubmit () {
      request
        .post('/rest/login', {
          code: this.code,
          codepwd: this.codepwd
        })
        .then((res) => {
          if (res.data.code === 0) {
            Toast.success('登录成功')
            this.showLogin = false
          } else {
            Toast.fail(res.data.msg)
          }
        })
    },
    async getRecommendGoods () {
      const res = await request('/mall/getActivityGoodsByLocation', {
        params: {
          location: 'MT'
        }
      })
      res.data.forEach((item) => {
        item.url = `/activity/product?sku=${item.id}`
      })
      this.goods = res.data
    }
  },
  computed: {}
}
</script>

<style lang="less">
@import '~swiper/swiper.min.css';

.root {
  padding-top: 0;
}

.recommend {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .recommend-container {
    width: 100%;
  }
}

.recommend-hd {
  padding: 13.248000000000001px 0;
  width: 100%;
  text-align: center;
}

.recommend-hd-bg {
  width: 177.74399999999997px;
  height: 20.976000000000003px;
}

.recommend-container {
  display: -webkit-flex;
  display: flex;
  position: relative;
  box-sizing: border-box;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: row;
  place-content: flex-start space-between;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-box-pack: justify;
  -webkit-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 2vw;
  background: #FFFFFF;
  padding-bottom: 13.248000000000001px;
}

.recommend-item {
  //height: 253.04px;
  position: relative;
  width: 46vw;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  background: #fff;
  margin-top: 9.936px;
  border-radius: 6.6240000000000006px;
  overflow: hidden;
  box-shadow: 0 0 5px 0 rgba(251, 155, 53, 0.3);
  //margin-left: 4.968px;
  //margin-right: 4.968px;
}

.recommend-img-wrapper {
  -webkit-box-align: center;
  height: 46vw;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.recommend-img {
  width: 46vw;
  height: 46vw;
}

.recommend-info {
  padding-left: 11.040000000000001px;
  padding-right: 9.936px;
  //height: 103.77600000000002px;
  padding-bottom: 4vw;
  text-decoration: none;
}

.recommend-title {
  margin-top: 12.696000000000002px;
  height: 40.848px;
  position: relative;
  overflow: hidden;
  line-height: 20.424px;
  text-overflow: ellipsis;
  min-height: 0;
}

.recommend-title-p {
  font-size: 14.352px;
  color: #333333;
}

.recommend-h {
  color: #ff5500;
}

.recommend-sign {
  font-size: 11px;
  margin-left: getUnit(-6);
  margin-bottom: getUnit(-1);
}

.recommend-price {
  font-size: 14px;
  line-height: 16.000000000000004px;
  color: #ff5500;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

@375vw: 3.75vw;

body {
  background-color: #ffffff;
}

.v-lazyload {
  display: block;
  width: 100%;
  height: 100%;
}

.root {
  padding-bottom: 70px;
  //padding-top: 44px;
  overflow: hidden;
}

.con-box {
  padding: 0 3.2vw;
}

.kq-tip-box {
  background: #ffd2a4;
  border-radius: 4px;
  width: 85vw;
  padding: 2.166vw;
  position: absolute;
  z-index: 10;
  left: 8vw;
  top: 30vw;
  color: #743412;
  font-size: 10px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border: 5px solid;
    border-color: transparent transparent #ffd2a4;
    top: -10px;
    position: absolute;
    left: 45.5vw;
  }
}

.banner-box {
  padding-top: 3.2vw;
  position: relative;
  //background-color: #fef8ec;

  .banner-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 32vw;
    background-color: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: .5s background-color;
  }
}

.banner-swiper-item {
  width: 93.6vw;
  height: 37.44vw;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.banner-swiper-pagination {
  .swiper-pagination-bullet {
    width: 8px;
    height: 3px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
  }
}

.nav-wrap {
  //background-size: 100%;
  //width: 96.8vw;
  //margin: 10px auto 0;
  background-size: 100%;
  //background-color: #fef8ec;
  padding: 10px;
}

.nav-box {
  padding-top: 1.1vw;

  .column-row {
    display: flex;
    //height: 12vw;
    flex-wrap: wrap;

    a {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 12vw;
      position: relative;

      img {
        display: block;
        width: 6.93vw;
        height: 6.93vw;
      }

      p {
        margin-top: 3px;
        font-size: 13px;
        color: #743412;
        font-weight: bold;
      }

      i {
        display: inline-block;
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 10px;
        color: #ffffff;
        background-color: #ff4b69;
        padding: 0 3px;
        border-radius: 2px;
      }
    }
  }

  .column-4 {
    a {
      height: 20vw;
    }
  }

  .column-line {
    height: 100%;
    width: 1px;
    background-color: #f5f5f5;
  }

  .row-line {
    height: 1px;
    background-color: #f5f5f5;
    width: 100%;
  }
}

.nav-tips {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;

  .tip {
    font-size: 10px;
    color: #CF9F79;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 14px;
      height: 14px;
    }

    span {
      margin-left: 4px;
    }
  }
}

.jd-box {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .act-wrap,
  .jd-wrap {
    width: 46.13vw;
  }

  .act-wrap {
    border-radius: 4px;
    height: 43.73vw;
    position: relative;
    display: block;
  }

  .jmall-wrap {
    display: block;
    height: 21vw;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  .jmall-book-wrap {
    overflow: hidden;
    display: block;
    height: 21vw;
    border-radius: 4px;
    margin-top: 1.73vw;
    position: relative;
  }
}

.cake-box {
  margin-top: 20px;
  padding-bottom: 3vw;

  .cake-banner {
    display: block;
    height: 33.6vw;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .cake-wrap {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    width: 93.6vw;
    padding: 2.66vw 2.66vw 0;
    margin: -2.66vw auto;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;

    .cake-shop {
      width: 21.33vw;
      margin-bottom: 2.66vw;

      img {
        display: block;
        width: 100%;
        height: 21.33vw;
        border: 2px solid #F7D8DF;
      }

      h3 {
        font-size: 14px;
        color: #000000;
        text-align: center;
        margin-top: 3px;
        line-height: 20px;
      }

      p {
        font-size: 11px;
        color: #ffffff;
        text-align: center;
        height: 16px;
        border-radius: 2px;
        margin-top: 5px;
        line-height: 16px;
      }

      .p-1 {
        background: rgba(154, 27, 31, 0.5);
      }

      .p-2 {
        background: rgba(96, 76, 94, 0.5);
      }

      .p-3 {
        background: rgba(176, 190, 136, 1);
      }
    }
  }
}

.afternoon-box {
  margin-top: 30px;

  .banner {
    display: block;
    width: 100%;
    height: 126/@375vw;
    background: rgba(167, 124, 113, 0.1);

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .afternoon-wrap {
    display: block;
    width: 100%;
    height: 100%;

    .inner {
      -webkit-overflow-scrolling: touch;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      position: relative;
      font-size: 0;

      .tea-item {
        width: 230/@375vw;
        display: inline-block;
        background: rgba(255, 255, 255, 1);
        //border-radius: 0 0 4px 4px;
        padding: 10px 7px;
        height: 38vw;

        &:first-child {
          margin-left: 12px;
          padding-left: 14px;
          border-radius: 0 0 0 4px;
        }

        &:last-child {
          padding-right: 14px;
          margin-right: 12px;
        }

        >img {
          display: block;
          width: 100%;
          height: 100/@375vw;
        }

        >div {
          font-size: 11px;
          text-align: center;
          margin-top: 8px;
          color: #000000;
        }
      }

      .tea-more {
        display: inline-block;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 1);
        padding: 10px 16px;
        height: 38vw;
        vertical-align: top;
        margin-right: 12px;
        border-radius: 0 0 4px 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;

          >img {
            display: block;
            width: 36/@375vw;
            height: 36/@375vw;
          }

          >span {
            font-size: 11px;
            color: rgba(24, 24, 24, 1);
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.fruits-box {
  margin-top: 20px;

  .banner {
    display: block;
    width: 100%;
    height: 126/@375vw;
    background: rgba(167, 124, 113, 0.1);

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .fruits-wrap {
    display: block;
    width: 100%;
    height: 100%;

    .inner {
      -webkit-overflow-scrolling: touch;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      position: relative;
      font-size: 0;

      .fruits-item {
        display: inline-block;
        background: rgba(255, 255, 255, 1);
        //border-radius: 0 0 4px 4px;
        padding: 10px 7px;
        height: 38.5vw;

        &:first-child {
          margin-left: 12px;
          padding-left: 14px;
          border-radius: 0 0 0 4px;
        }

        &:last-child {
          padding-right: 14px;
          margin-right: 12px;
        }

        >img {
          display: block;
          width: 100/@375vw;
          height: 100/@375vw;
        }

        >div {
          font-size: 11px;
          text-align: center;
          margin-top: 8px;
          color: #000000;
          width: 100/@375vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .tea-more {
        display: inline-block;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 1);
        padding: 10px 16px;
        height: 38.5vw;
        vertical-align: top;
        margin-right: 12px;
        border-radius: 0 0 4px 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;

          >img {
            display: block;
            width: 36/@375vw;
            height: 36/@375vw;
          }

          >span {
            font-size: 11px;
            color: rgba(24, 24, 24, 1);
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.gift-box {
  margin-top: 20px;
  padding-bottom: 3vw;

  .flower-banner {
    display: block;
    height: 33.6vw;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .giftbag-wrap {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    width: 93.6vw;
    padding: 5.5vw 2.66vw 2vw;
    margin: -2.66vw auto;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .giftbag-item {
    width: 26.6vw;
    padding-bottom: 3vw;

    img {
      display: block;
      width: 20vw;
      height: 20vw;
      margin: 0 auto;
    }

    h3 {
      font-size: 14px;
      color: #000000;
      text-align: center;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.life-box {
  margin-top: 20px;
  height: 83.73vw;
  position: relative;
  overflow: hidden;

  >.v-lazyload {
    position: absolute;
    top: 0;
    left: 0;
  }

  .m-links {
    //width: 55.06vw;
    //height: 32.133vw;
    position: absolute;
    right: 3.46vw;
    top: 2.66vw;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    >.v-lazyload {
      position: absolute;
      left: 0;
      top: 0;
    }

    a {
      width: 21.4vw;
      height: 30vw;
      display: block;
      border-radius: 4px;
      overflow: hidden;
      color: #ffffff;
      position: relative;

      >div {
        position: absolute;
        height: 14px;
        line-height: 14px;
        width: 12vw;
        background: linear-gradient(90deg, rgba(255, 103, 78, 1) 0%, rgba(255, 0, 41, 1) 100%);
        border-radius: 4px 0 4px 0;
        left: 0;
        top: 0;
        font-size: 10px;
        text-align: center;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .inner-box {
    width: 93.6vw;
    //height: 44.8vw;
    background-color: #ffffff;
    margin: 36.5vw auto 0;
    position: relative;
    z-index: 2;

    .cc-box {
      display: flex;

      .cc-l {
        width: 24vw;
        height: 21.866vw;
        line-height: 21.866vw;
        font-weight: bold;
        font-size: 14px;
        padding-left: 3.2vw;
        color: #000000;
      }

      .yundong-img {
        width: 66.4vw;
        height: 16.53vw;
        display: block;
        margin-top: 3.2vw;
      }

      .cc-r {
        width: 69.6vw;
        overflow: hidden;
      }
    }

    .cc-line {
      width: 100%;
      height: 1px;
      background-color: #D8D8D8;
    }
  }

  .vip-swiper {
    overflow: initial;
    margin-top: 3.2vw;

    .vip-swiper-item {
      width: 66.4vw;
      height: 15.47vw;
      display: block;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }
    }

    .vip-links {
      position: absolute;
      top: 8.6vw;
      left: 16.5vw;
      font-size: 0;

      .link-item {
        font-size: 11px;
        line-height: 16px;
        padding: 1vw 2vw;
        background-color: #ffffff;
        border-radius: 10px;
        margin-left: 2vw;

        &:first-child {
          margin-left: 0;
        }
      }

      &.qq {
        .link-item {
          color: #000000;
        }
      }

      &.iqiyi {
        .link-item {
          color: #6CBE40;
        }
      }

      &.youku {
        .link-item {
          color: #1294FF;
        }
      }
    }

    .swiper-slide {
      transition: 300ms;
    }

    .vip-swiper-pagination {
      bottom: -8px;
      top: initial;
      width: 12vw;
      left: 26vw;
      border-radius: 4px;
      overflow: hidden;

      .swiper-pagination-progressbar-fill {
        background-color: #CF9F79;
      }
    }
  }
}
</style>

<!--<style lang="less">-->
<!--body {-->
<!--  filter: grayscale(100%);-->
<!--  -webkit-filter: grayscale(100%);-->
<!--}-->
<!--</style>-->
